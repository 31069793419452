'use client'

import { useRouter } from 'next/navigation'
import { useSetAtom } from 'jotai'
import PageIntroBannerComponent from '@/components/PageIntroBanner'
import { KOR_CANCER_TYPES, GUIDES_LIST_REDIRECT_PATH } from '@/consts'
import { activeMidGuideAtom, INITIAL_ACTIVE_MID_GUIDE } from '@/stores'
import type { CancerType } from '@/types/cancer'

const PageIntroBanner = ({ cancerType }: { cancerType: CancerType }) => {
  const { push } = useRouter()
  const setActiveMidGuide = useSetAtom(activeMidGuideAtom)
  const guideListPathname = GUIDES_LIST_REDIRECT_PATH[cancerType]

  const resetActiveGuide = () => {
    setActiveMidGuide(INITIAL_ACTIVE_MID_GUIDE)
  }

  const handleClick = () => {
    push(guideListPathname)
    resetActiveGuide()
  }

  return (
    <PageIntroBannerComponent
      title={
        <>
          <em className="text-lavendar-40 not-italic">
            {KOR_CANCER_TYPES[cancerType]}
          </em>
          에 대해 <br />
          무엇이든 찾아보세요
        </>
      }
      subTitle="신뢰할 수 있는 정보만 모두 모았습니다."
      buttonTitle="예방부터 치료 후 관리까지"
      buttonSubTitle="가이드 목차 전체 보기"
      gaSelector="guide_main_toc"
      onClick={handleClick}
    />
  )
}

export default PageIntroBanner
