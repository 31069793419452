'use client'

import clsx from 'clsx'
import { ArrowIcon } from '@/components/NewIcons'
import { useCreateBoardModal } from '@/hooks/useCreateBoardModal'

const STYLE_CONFIG = {
  text: 'text-lavendar-30',
  fill: '!fill-lavendar-30',
  background: 'bg-lavendar-100',
}

const CREATE_BANNER = {
  content: (
    <>
      더 궁금한 내용이 있나요? <br />
      직접 질문을 남겨주세요. <br />
      <span className="font-bold text-lavendar-30">
        암 전문 의료팀이 답변드립니다.
      </span>
    </>
  ),
  linkText: '질문하고 맞춤 답변 받기',
}

/**
 * 질문작성하기 배너
 */
const CreateDocumentBanner = ({ gaSelector = '' }: { gaSelector?: string }) => {
  const { handleCreateBoardAuth } = useCreateBoardModal()

  return (
    <button
      data-ga={gaSelector}
      type="button"
      className={clsx(
        'block',
        'w-full',
        'text-left',
        'leading-7',
        'border',
        'border-gray-20',
        'rounded-lg',
        'bg-white',
      )}
      onClick={() => {
        handleCreateBoardAuth()
      }}
    >
      <div
        className={clsx(
          'flex',
          'items-center',
          'justify-between',
          'px-4',
          'py-4',
          'rounded-t-lg',
          STYLE_CONFIG.background,
        )}
      >
        <p className="text-gray-80">{CREATE_BANNER.content}</p>
        <img
          width={60}
          height={60}
          src="/assets/images/board_question_image.png"
          alt=""
        />
      </div>
      <div className={`flex items-center px-4 py-2.5 ${STYLE_CONFIG.text}`}>
        <span>{CREATE_BANNER.linkText}</span>
        <ArrowIcon className={STYLE_CONFIG.fill} />
      </div>
    </button>
  )
}

export default CreateDocumentBanner
