import { useRouter } from 'next/navigation'
import clsx from 'clsx'
import { ChevronIcon } from '@/components/NewIcons'
import { SUB_GUIDE_BUTTON_GA_SELECTOR } from '@/consts'
import type { CancerType } from '@/types/cancer'
import { encodeTextWithoutSpace } from '@/utils/guide'

interface Props {
  midGuideId: string
  subGuideLabel: string
  cancerType: CancerType
}

const LinkToSubGuideButton = ({
  midGuideId: id,
  subGuideLabel,
  cancerType,
}: Props) => {
  const { push } = useRouter()

  const handleClick = () => {
    push(`/${cancerType}/guides/${id}#${encodeTextWithoutSpace(subGuideLabel)}`)
  }

  return (
    <button
      data-ga={SUB_GUIDE_BUTTON_GA_SELECTOR}
      type="button"
      onClick={handleClick}
      className="flex justify-between w-full text-gray-100"
    >
      <div
        className={clsx(
          'flex',
          'items-center',
          'justify-between',
          'w-full',
          'font-bold',
          'leading-7',
          'py-3',
          'border-b',
          'border-b-gray-30',
        )}
      >
        {subGuideLabel}
        <ChevronIcon className="!fill-gray-70" />
      </div>
    </button>
  )
}
export default LinkToSubGuideButton
