import { ReactNode } from 'react'
import clsx from 'clsx'
import MaterialSymbol from '@/v1/MaterialSymbol'

const PageIntroBanner = ({
  title = '',
  subTitle = '',
  buttonTitle = '',
  buttonSubTitle = '',
  gaSelector = '',
  onClick,
}: {
  title: ReactNode
  subTitle: string
  buttonTitle: string
  buttonSubTitle: string
  gaSelector: string
  onClick?: () => void
}) => {
  const handleClick = () => {
    onClick?.()
  }

  return (
    <div className="bg-lavendar-100 px-md pt-6 pb-8">
      <h4 className="text-gray-90 whitespace-pre-line prose-h4">{title}</h4>
      <p className="text-gray-80 mt-2 mb-4 leading-7">{subTitle}</p>

      <button
        data-ga={gaSelector}
        type="button"
        className={clsx(
          'flex',
          'items-center',
          'justify-between',
          'w-full',
          'pl-4',
          'pr-2',
          'py-2',
          'bg-lavendar-500',
          'rounded-lg',
          'shadow-sm',
        )}
        onClick={handleClick}
      >
        <div className="text-body leading-7 text-white text-left">
          <p>{buttonTitle}</p>
          <p className="font-bold">{buttonSubTitle}</p>
        </div>
        <MaterialSymbol size={20} name="chevron_right" className="fill-white" />
      </button>
    </div>
  )
}

export default PageIntroBanner
